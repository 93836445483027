<template>
  <div class="projectAPP" :style="projectindebty ? '' : 'height: 965px;'">
    <div class="schsoolhader">
      <span>{{ projecttitle }}</span>

      <span
        v-show="projectindebty"
        slot="reference"
        class="referencecss"
        style="margin-left: 70%; cursor: pointer; position: absolute"
        @click="getback"
      >
        <i class="el-icon-sort" style="transform: rotate(270deg)"></i>
        返回上一级</span
      >

      <el-popover
        v-show="projectindebty == false"
        placement="top"
        width="100"
        v-model="visible"
        class="povervisiblecss"
      >
        <div style="text-align: center; height: 40px; line-height: 40px">
          <el-button
            size="mini"
            type="text"
            style="margin-right: 15px"
            @click="visible = false"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="visibleoperate"
            >确定</el-button
          >
        </div>
        <span slot="reference" class="referencecss" style="margin-left: 70%">
          <i class="el-icon-sort" style="transform: rotate(270deg)"></i>
          退出登录</span
        >
      </el-popover>
    </div>
    <div class="projectcsstop" v-show="projectindebty">
      <div class="projecttit">
        <div class="projecttitblue"></div>
        <div class="projectname">基础设置</div>
      </div>
      <div class="projectcssthree">
        <!-- banner图 -->
        <div class="projectimg">
          <span>设置banner图</span>
          <el-upload
            :action="'/admin/Upload/upload'"
            :headers="{token:this.tokened}"
            list-type="picture-card"
            :on-success="bannerimgadd"
            
            class="prouploadimg"
            :file-list="fileList"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
        <!-- 模板 -->
        <div class="protemplate">
          <span>选择模板</span>
          <div class="protempsel">
            <div
              class="protemplevelcz"
              v-for="(item, index) in prozhezhaodata"
              :key="index"
              :class="procsszeindex == index ? 'procssze' : ''"
              @click="checkedtempl(index)"
            >
              <!--  // <div class="protemplevel"></div>-->
              <img v-if="index == 1" src="../assets/blue.png" alt="" />
              <img v-if="index == 2" src="../assets/green.png" alt="" />
              <img v-if="index == 0" src="../assets/brown.png" alt="" />
              <img
                v-if="index == 3"
                src="../assets/Snipaste_2021-11-02_13-42-07.jpg"
                alt=""
              />
            </div>
          </div>
          <span>点击选择颜色</span>
          <div class="clkcolor">
            <el-color-picker v-model="procolor1"></el-color-picker>
          </div>
        </div>
        <!-- 学校logo -->
        <div class="projectcolor">
          <span>上传学校logo图</span>
          <el-upload
            :action="'/admin/Upload/upload'"
            list-type="picture-card"
            :on-success="projectlogodata"
            class="rojectlogoimg"
            :headers="{token:this.tokened}"
            :file-list="fileListlogo"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </div>
        <!-- 设置网站连接 -->
        <div class="projectsrc">
          <span>设置连接</span>
          <div class="projectsrcitem">
            <el-input
              style="margin-bottom: 15px"
              v-model="projectsrclink"
              placeholder="请输入连接"
            ></el-input>
            <span>请输入版权</span>
            <el-input
              style="margin-bottom: 90px; margin-top: 15px"
              v-model="projectcopyright"
              placeholder="请输入版权"
            ></el-input>
          </div>
          <el-button type="success" @click="savedata">保存</el-button>
        </div>
      </div>
    </div>
    <div class="projectcss">
      <div class="projectcssheader">
        <div>项目列表</div>
        <el-button
          style="position: absolute; left: 150px; top: -8px"
          @click="yulan()"
          >预览页面</el-button
        >
        <div class="projectcalss">
          <div class="projecttitle">
            <div class="projectblue"></div>
            <div class="projectname">项目大类</div>
          </div>
          <div class="projectbanner">
            <div
              class="procalss"
              @click="increasecalss"
              v-show="projectindebty"
            >
              <i class="el-icon-plus"> </i><span>新增大类</span>
            </div>
            <div
              class="projectbanname"
              v-for="(item, index) in projetcdata"
              :key="index"
            >
              <div
                class="projectbandiv"
                @click="checkedproclss(index)"
                :class="projetchecked == index ? 'procss' : ''"
              >
                <span class="projectspan">{{
                  item.name + "(" + item.type + ")"
                }}</span>
                <span class="projectspanright" v-show="projectindebty">
                  <el-popconfirm
                    title="确定删除吗？"
                    @confirm="delprojectdata(index, item.id)"
                  >
                    <i class="el-icon-delete" slot="reference"></i>
                  </el-popconfirm>
                  <i
                    class="el-icon-edit"
                    @click="modificationprojetname(item.value, index, item)"
                  ></i>
                </span>
              </div>
            </div>
            <div class="projetdataname" v-show="projetnameboolan">
              <el-input
                v-model="projetdataname"
                placeholder="请输入项目类型"
              ></el-input>
              <el-input
                v-model="projetdatatitle"
                placeholder="请输入项目名称"
              ></el-input>
              <el-button type="primary" @click="addprojectdata">确定</el-button>
            </div>
            <!-- 修改 -->
            <div class="projetnamenew" v-show="projetdatanewboolan">
              <el-input
                v-model="projetdatanew"
                placeholder="请输入项目类型"
              ></el-input>

              <el-input
                v-model="projetdatanewname"
                placeholder="请输入项目类型"
              ></el-input>

              <el-button type="primary" @click="addnewprojetname"
                >确定修改</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="projectcssright">
        <div class="projectrdiv">
          <div class="projectright">项目列表</div>

          <div class="projectripro" @click="addlevelprojet">
            <i class="el-icon-plus"></i>新增项目
          </div>
        </div>
        <div class="projectrbot" v-if="projetcdata[projetchecked]">
          <div
            class="projectrbottitle"
            v-for="(item, index) in projetcdata[projetchecked].children"
            :key="index"
          >
            <div class="projectname">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="top-start"
              >
                <span class="projespan">{{ item.name }}</span>
              </el-tooltip>

              <span class="projectspanright">
                <el-popconfirm
                  title="确定删除吗？"
                  @confirm="delprojetlevelname(item, index)"
                >
                  <i class="el-icon-delete" slot="reference"> </i>
                </el-popconfirm>
                <i
                  class="el-icon-edit"
                  @click="amendprojetlevelname(item, index)"
                ></i
              ></span>
            </div>
            <div class="projectsp">
              <span
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                >{{ item.explain }}</span
              >
            </div>
            <div class="projectxq" @click="progettouter(item)">开始设置</div>
          </div>
        </div>
      </div>
    </div>
    <div class="projeceldialog">
      <el-dialog
        title="新建项目"
        :visible.sync="projectlevelboolan"
        width="30%"
      >
        <div class="projeceldialogdiv">
          <el-input
            v-model="projectlevelstr"
            placeholder="请输入项目名称"
          ></el-input>

          <el-input
            v-model="projectleveexplain"
            placeholder="请输入项目项目负责人"
          ></el-input>
          <div class="projeceldibutton">
            <el-button plain @click="projectlevelboolan = false"
              >取消</el-button
            >
            <el-button type="primary" @click="confimaddprojetlevel"
              >确定</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  adzhuanlanpro,
  getzhuanlan,
  addproject,
  bannerimg,
  getbanner,
  shoolzhuanlan,
} from "../api/api";
export default {
  data() {
    return {
      projectindebty: true, //登录身份验证

      projecttitle: "", //设置的专栏名称

      projetcdata: [], //项目大类

      projetdataname: "", //新增大类的名字

      projetdatatitle: "", //新增的项目名称

      projectalldata: [], //所有项目列表

      projectleveexplain: "", //新增的项目说明

      dialogVisibleproject: false, //新建项目的

      projetnameboolan: false, //是否开启新增大类

      projetdatanew: "", //修改项目的名字

      projetdatanewname: "", //修改项目的类型

      projetd_id: "", //修改的项目id

      projetleve_id: "", //修改二级项目的id

      projetdatanewboolan: false, //修改项目名称

      projetdatanewindex: "", //修改项名称的id

      projetchecked: 0, //   选中的项目大类下标

      projectlevelboolan: false, //修改二级项目的名称

      projectlevelstr: "", //输入的二级项目名称

      visiblepopoverdel: false, //删除框

      visible: false, //学校退出登录

      /* 上传图片属性*/
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,

      fileList: [],

      prozhezhaodata: [{}, {}, {}, {}], //遮罩层数据

      procsszeindex: 0, //模板的遮罩层

      procolor1: "#409EFF", //用户选中的颜色

      projectsrclink: "", //用户输入的连接

      projectcopyright: "", //用户输入的版权

      projetctzhuanlanid: "", //专栏的id

      projecttemplate: "", //选择的模板

      fileListlogo: [], //  学校logo

      fileListlogoredata: {}, //学校logo上传后的返回值

      yulanurl: "", // 预览页面
      tokened:window.sessionStorage.getItem('tokened'),
    };
  },
 
  mounted() {
    this.getzhuanlandata();

    this.projetctzhuanlanid = this.$route.query.id;

    this.projecttitle = this.$route.query.name;

    this.getbannerimg();

    //身份验证管理员为1  学校为2
    console.log(JSON.parse(window.localStorage.getItem("token")).identity);

    let identity = JSON.parse(window.localStorage.getItem("token")).identity;

    if (identity == 1) {
    } else {
      this.projectindebty = false;
    }
  },
  methods: {
    //预览
    yulan() {
      console.log(1111);

      // window.open("http://192.168.0.53:8080/#/zjzl");

      console.log(this.yulanurl);
      window.open(this.yulanurl);
    },

    //学校退出登录
    visibleoperate() {
      console.log(1111);
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push("/login");
    },

    //返回上一级
    getback() {
      this.$router.push("/adminschool");
    },

    //是否删除
    referencedata(item) {
      console.log(item);
    },

    //专栏的项目数据
    getzhuanlandata() {
      getzhuanlan(this.$route.query.id).then((res) => {
        if (res.data.code == 200) {
          this.projetcdata = res.data.data;

          // console.log(res.data.other.color);
          // console.log(res.data.other.copyright);
          // console.log(res.data.other.model);
          // console.log(res.data.other.school_url);

          this.procolor1 = res.data.other.color;
          this.projectcopyright = res.data.other.copyright;
          this.projecttemplate = res.data.other.model;

          if (this.projecttemplate == "brown") {
            this.procsszeindex = 0;
          } else if (this.projecttemplate == "blue") {
            this.procsszeindex = 1;
          } else if (this.projecttemplate == "green") {
            this.procsszeindex = 2;
          } else if (this.projecttemplate == "yellow") {
            this.procsszeindex = 3;
          }

          this.projectsrclink = res.data.other.school_url;
          this.fileListlogo = res.data.other.url;

          // console.log(res.data.other.zl_url);
          this.yulanurl = res.data.other.zl_url;

          this.projectalldata = res.data.all;

          // console.log(this.fileListlogo);
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },

    //获取上传图片数据
    getbannerimg() {
      getbanner(this.projetctzhuanlanid).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.fileList = res.data.data;
          console.log(this.fileList);
        } else {
          this.fileList = [];
        }
      });
    },
    /*   上传图片的方法 */
    handleRemove(file) {
      // file.percentage = 1;
      console.log(file);

      // bannerimg("", "", this.projetctzhuanlanid, file.id, 0).then((res) => {
      //   console.log(res);

      // });
      // shoolzhuanlan(
      //         "",
      //         "",
      //         "",
      //         "",
      //         "",
      //         "",
      //         "",
      //         this.projetctzhuanlanid,
      //         file.url,
      //         0
      //       )
      let project_id = this.$route.query.id;
      bannerimg("", "", "", file.id, 0, project_id).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.getzhuanlandata();
          this.getbannerimg();
        }
        // this.getbannerimg();
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file);
    },
    handleDownload(file) {
      console.log(file);
    },

    //ban图上传成功
    bannerimgadd(res, file, fileList) {
      console.log(res);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        bannerimg(res.file_name, res.url, this.projetctzhuanlanid).then(
          (resed) => {
            // console.log(resed);
            this.getbannerimg();
          }
        );
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    //点击切换选中模板
    checkedtempl(index) {
      console.log(index);
      this.procsszeindex = index;

      if (index == 0) {
        this.projecttemplate = "brown";
      } else if (index == 1) {
        this.projecttemplate = "blue";
      } else if (index == 2) {
        this.projecttemplate = "green";
      } else if (index == 3) {
        this.projecttemplate = "yellow";
      }
      console.log(this.projecttemplate);
    },
    //logo图上传成功后
    projectlogodata(res) {
      console.log(res);
      this.fileListlogoredata = res;
    },
    //保存选中数据
    savedata() {
      // this.$router.push("/adminbasics");

      shoolzhuanlan(
        "",
        "",
        "",
        this.projecttemplate,
        this.projectcopyright,
        this.procolor1,
        this.projectsrclink,
        this.projetctzhuanlanid,
        this.fileListlogoredata.url
      ).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.getzhuanlandata();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
        console.log(res);
      });
    },
    //开启新增大类
    increasecalss() {
      this.projetnameboolan = !this.projetnameboolan;
    },
    //添加项目大类
    addprojectdata() {
      console.log(this.$route.query.id);
      if (this.projetdataname != "" && this.projetdatatitle != "") {
        adzhuanlanpro(
          this.$route.query.id,
          this.projetdatatitle,
          this.projetdataname
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getzhuanlandata();
            this.projetnameboolan = false;
            this.projetdataname = "";
            this.projetdatatitle = "";
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      }
    },
    //删除项目大类
    delprojectdata(index, id) {
      // if (this.projetcdata.length > 1) {
      //   this.projetcdata.pop();
      // } else {
      //   this.$message({
      //     message: "无法删除",
      //     type: "warning",
      //   });
      // }

      adzhuanlanpro(this.projetctzhuanlanid, "", "", id, 0).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.getzhuanlandata();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
      console.log(index);
    },
    //修改项目名字
    modificationprojetname(value, index, item) {
      this.projetdatanewboolan = true;

      this.projetdatanew = item.type; //修改的项目类型
      this.projetdatanewname = item.name; //修改的项目名称
      this.projetd_id = item.id; //修改的项目id

      this.projetdatanewindex = index;
    },
    //确定修改项目名字
    addnewprojetname() {
      adzhuanlanpro(
        this.projetctzhuanlanid,
        this.projetdatanewname,
        this.projetdatanew,
        this.projetd_id
      ).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.getzhuanlandata();
          this.projetdatanewboolan = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    //选中项目大类
    checkedproclss(index) {
      this.projetchecked = index;
    },
    //新增二级项目
    addlevelprojet() {
      console.log(1111);
      this.projectlevelboolan = true;
      this.projectlevelstr = "";
      this.projectleveexplain = "";
    },
    //确定新增二级项目
    confimaddprojetlevel() {
      if (this.projectlevelstr != "" && this.projectleveexplain) {
        addproject(
          this.projetctzhuanlanid,
          this.projectlevelstr,
          this.projectleveexplain,
          this.projetcdata[this.projetchecked].id,
          this.projetleve_id
        ).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.projetleve_id = ""; //清除项目id
            this.getzhuanlandata();
            this.projectlevelboolan = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入数据",
          type: "warning",
        });
      }
    },
    //修改二级项目
    amendprojetlevelname(item, index) {
      console.log("修改项目");
      console.log(item.explain, item.name);

      this.projectlevelboolan = true;

      this.projectlevelstr = item.name; //项目
      this.projectleveexplain = item.explain; //项目说明
      this.projetleve_id = item.id; //二级项目id
    },
    //删除二级项目
    delprojetlevelname(item, index) {
      console.log(this.projetcdata[this.projetchecked].id);

      addproject(
        this.projetctzhuanlanid,
        "",
        "",
        this.projetcdata[this.projetchecked].id,
        item.id,
        0
      ).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.getzhuanlandata();
          this.projectlevelboolan = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
      console.log("删除二级项目");
      console.log(item, index);
    },

    //跳转到项目详情
    progettouter(item) {
      console.log(item.name);

      this.$router.push({
        path: "/adminbasics",
        query: {
          project_id: item.id,
          name: item.name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectAPP {
  background-color: #f8f9ff;
  .schsoolhader {
    width: 100%;
    height: 70px;
    background-color: #fff;
    line-height: 70px;
    margin-bottom: 40px;
    span {
      margin-left: 4%;
      font-weight: 800;
      color: #323b61;
    }
  }
  .projectcsstop {
    width: 1400px;
    height: 400px;
    background-color: #fff;
    margin: 25px auto;
    border-radius: 5px;
    .projecttit {
      display: flex;
      position: relative;
      left: 30px;
      top: 30px;
      line-height: 20px;
      .projecttitblue {
        width: 5px;
        height: 20px;
        background-color: #323b61;
        margin-right: 20px;
      }
      .projectname {
        font-size: 18px;
        font-weight: 800;
      }
    }
    .projectcssthree {
      height: 280px;
      margin-top: 50px;
      margin-left: 30px;
      display: flex;
      .projectimg {
        width: 350px;
        .prouploadimg {
          display: flex;
          margin-top: 40px;
        }
        .el-button {
          margin-top: 50px;
          background-color: #323b61;
          border: 0px;
          width: 100px;
          height: 40px;
          border-radius: 4px;
        }
      }
      .protemplate {
        width: 350px;
        margin-left: 30px;
        .protempsel {
          display: flex;
          overflow: auto;
          margin-top: 30px;
          margin-bottom: 40px;
          .protemplevelcz {
            margin-right: 20px;
            .protemplevel {
              width: 100px;
              height: 100px;
              background: url("../assets/logo.png") no-repeat;
              background-size: 100px;
            }
          }
        }
        .clkcolor {
          // margin-bottom: 20px;
          margin-top: 20px;
        }
      }
      .projectcolor {
        position: relative;
        // margin-left: 150px;
        width: 260px;

        .rojectlogoimg {
          position: absolute;
          width: 400px;
          top: 50px;
        }
      }
      .projectsrc {
        margin-left: 105px;
        .projectsrcitem {
          margin-top: 30px;
          width: 250px;
        }
        .el-button {
          // margin-top: 50px;
          background-color: #323b61;
          border: 0px;
          width: 100px;
          height: 40px;
          border-radius: 4px;
        }
      }
    }
  }
  .projectcss {
    width: 1400px;
    height: 630px;
    background-color: #fff;
    margin-top: 20px;
    margin: 0 auto;
    display: flex;
    .projectcssheader {
      width: 50%;
      height: 50%;
      margin: 2% 2%;
      position: relative;
      .projectcalss {
        width: 40%;
        margin-left: 65px;
        .projecttitle {
          display: flex;
          margin-top: 20px;
          line-height: 16px;
          .projectblue {
            background-color: #b3a487;
            width: 4px;
            height: 19px;
            margin-right: 12px;
          }
          .projectname {
            font-weight: 900;
            // margin-right: 65%;
          }
        }
        .projectbanner {
          .procalss {
            width: 364px;
            height: 50px;
            background-color: #fff;
            text-align: center;
            line-height: 50px;
            margin-top: 20px;
            color: #323b61;
            border-radius: 5px;
            border: 1px solid #dfe3f5;
            cursor: pointer;
            span {
              font-weight: 800;
            }
          }
          .projectbanname {
            margin-top: 20px;
            position: relative;
            .projectbandiv {
              background-color: #edf0fd;
              width: 362px;
              height: 60px;
              line-height: 60px;
              position: relative;
            }
            .projectbandiv:hover {
              background-color: #323b61;
              color: #fff;
            }
            .projectspan {
              margin-left: 6%;
            }
            .projectspanright {
              position: absolute;
              right: 10px;
              .el-icon-edit {
                margin-left: 10px;
              }
            }
          }

          .projetdataname {
            margin-top: 20px;
            width: 360px;
            .el-input {
              margin-bottom: 15px;
            }
          }
          .projetnamenew {
            width: 360px;
            // display: flex;
            margin-top: 10px;
            .el-input {
              //  width: 90%;
              margin-right: 41px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    .projectcssright {
      width: 978px;
      height: 464px;
      box-shadow: 0px 0px 16px 0px rgba(153, 153, 153, 0.1);
      position: relative;
      left: -6%;
      top: 11%;

      .projectrdiv {
        display: flex;
        .projectright {
          width: 72px;
          height: 18px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
          margin-top: 37px;
          margin-left: 29px;
        }
        .projectleft {
          display: flex;
          position: absolute;
          left: 20%;
          top: -10%;
          height: 40px;
          line-height: 40px;
          .el-input {
            margin-right: 20px;
          }
        }
        .projectripro {
          width: 120px;
          height: 40px;
          background: #323b61;
          border-radius: 20px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          position: relative;
          left: 65%;
          top: 36px;
          cursor: pointer;
        }
      }
      .projectrbot {
        margin-left: 30px;
        margin-top: 50px;
        .projectrbottitle {
          height: 75px;
          width: 700px;
          border-bottom: 1px solid;
          position: relative;

          margin-bottom: 20px;
          .projectname {
            .projespan {
              color: #323b61;
              width: 530px;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .projectspanright {
              margin-left: 25px;
              color: #cecece;
              cursor: pointer;
              position: absolute;
              right: 100px;
              top: 5px;
              .el-icon-delete {
                margin-right: 20px;
              }
            }
          }
          .projectsp {
            // background-color: red;
            margin-top: 15px;
            font-size: 16px;
            color: #999999;
          }
          .projectxq {
            width: 64px;
            height: 17px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #323b61;
            line-height: 24px;
            position: absolute;
            right: 2%;
            top: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
//选中大类
.procss {
  background-color: #323b61 !important;
  color: #fff !important;
}
//点击添加遮罩层
.procssze {
  background-color: #333333;
  opacity: 0.5;
}
</style>
